#top-nav-bar {
    .nav-link(@prefix) {
        li.link-@{prefix} a:before {
            content: url("@{image-assets}layouts/@{prefix}_link.png");
        }
    }

    .nav-explore {
        .nav-link(travel);
        .nav-link(arcade);
        .nav-link(trade);
        .nav-link(auction);
    }

    .nav-community {
        .nav-link(mail);
        .nav-link(forum);
    }

    .nav-settings {
        .nav-link(account);
        .nav-link(settings);
    }
}