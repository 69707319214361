body{
    color: #222222;
    font-family: Veranda, sans-serif;
    font-size: 11px;
    height: 100%;
}
h1{
    color: #222222;
    font-family: "Trebuchet MS", sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1em;
    padding-bottom: 2px;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid #bebebe;
    padding-left: 0px;
    margin: 0;
}
a:link {
    color:#222222;
    text-decoration:none;
}
a:visited {
    color:#222222;
    text-decoration:none;
}
a:hover {
    color:#c1c1c1;
    text-decoration:none;
}

input,textarea,select,.btn {
    -webkit-appearance:none;

    padding: 0.5em;
    line-height: 1.2em;
    width: 75%;

    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ccc));
    -webkit-appearance: none;
    -webkit-box-shadow: 1px 1px 1px #fff;
    -webkit-border-radius: 0.5em;

    border: 1px solid #D0D0D0;
    background: #FBFBFB;
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
    -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
    -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 14px;
    color: #444;
}

input[type="text"]:focus,select:focus,textarea:focus {
    background: #fff;
    border: 1px solid #52A8E8;
    -webkit-box-shadow: 0 0 1px 1px #eee;
    box-shadow: 0 0 1px 1px #eee;
    -moz-box-shadow: 0 0 1px 1px #eee;
}