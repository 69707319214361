.mail {
    width: 100%;

    thead {
        padding: 5px;
        background: #918E8E;
        color: #FFFFFF;
        font-weight: bold;
        border-bottom: 1px solid #FFF;
        text-align: center;

        td:nth-child(1) {
            width: 70%;
        }

        td:nth-child(2) {
            width: 70%;
        }
    }

    tfoot {
        text-align: center;
    }

    .message {
        padding: 5px;
        border-bottom: 1px solid #FFFFFF;

        td:nth-child(1) {
            width: 3%;
        }

        td:nth-child(2) {
            width: 5%
        }

        td:nth-child(3) {
            width: 62%;
        }

        td:nth-child(4) {
            width: 30%;
        }
    }

    .message.message-unread {
        font-weight: bold;
        background-color: #FFFFFF;
    }

    .message.message-read {
        background-color: #F0F0F0;
    }
}