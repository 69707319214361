html, body{
	margin: 0;
	padding: 0;
	height: 100%;
}

body{
	background: #222222;
	color: #222222;
	font-family: Verdana, sans-serif;
	font-size: 11px;
	font-style: normal;
	font-weight: normal;
	text-transform: normal;
	letter-spacing: normal;
	line-height: 1.5em;
}


.clear{
	clear: both;
}

.left{
	float: left;
}

.right{
	float: right;
}

.wrapper {
	width: 992px;
	margin: 0 auto -70px;
	min-height: 100%;
	height: auto!important;
	height: 100%;	
}

textarea {
  outline: none;
}

.userbox{
	width: 308px; /* 328px */
    min-height: 70px;
	padding: 10px;
	background: #3d3d3d;
	color: #FFF;
	font-size: 12px;
    opacity: 0.95;
}

.userbox img{
	max-width:50px;
	max-height:50px;
	float:left;
	margin-right:10px;
	background:#FFF;
}

.userbox a:link {
	color:#FFFFFF;
	text-decoration:none;
}  
    
.userbox a:visited {
	color:#FFFFFF;
	text-decoration:none;
}  

.userbox a:hover {
	color:#c1c1c1;
	text-decoration:none;
}  

.userbox a:active {
	color:#FFFFFF;
	text-decoration:none;
} 

.userbox .notif, .userbox a.notif{
	background:#292929;
	padding:3px;
	margin-top:6px;
	color:#FFF;
	text-decoration:none;
	display:block;
}

.userbox a.notif:hover{
	background:#222;
}

.sf-menu{
	float:right;
	background:#3d3d3d;
}

.sf-menu li a {
	font-family: Veranda, sans-serif;
	font-size: 18px;
	color: #FFFFFF;
	font-weight: bold;
	text-transform: uppercase;
	padding: 10px;
	text-decoration: none;
}

.sf-menu li {
	background:#3d3d3d;
	border-right: 1px solid #222222;
	font-weight: none;
    opacity: 0.95;
	
}

.sf-menu li:last-child{
	border-right:none;
	font-weight: none;
}

.sf-menu li li,.sf-menu li li a{
	background: #292929;
	font-size: 14px;
	color: #FFFFFF;
	border: none;
	font-weight: none;
        width: 125px;
}

.sf-menu li li a:hover{
	color:#222;
	background:#FFF;
	font-weight: none;
}

.sf-menu li:hover, .sf-menu li.sfHover, .sf-menu a:focus, .sf-menu a:hover, .sf-menu a:active {
	background:#292929;
	outline:0;
	font-weight: none;
    opacity: 1;
}

.logo{
	float: right;
	color: #FFFFFF;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 60px;
	margin-top: -40px;
	letter-spacing: -1px;
	font-family: 'Ropa Sans', sans-serif;
}

.main{
	background:#FFF;
	padding: 8px 10px 35px 15px;
	margin-top: 20px;
	margin-bottom: 40px;
        font-family: Verdana, sans-serif;
        font-size: 11px;
        font-style: normal;
        font-weight: normal;
        text-transform: normal;
        letter-spacing: normal;
        line-height: 1.5em;
        color: #222222;
	letter-spacing:0px;
        position:relative;
}

.main .tbl{
	display:table; 
}


.tbl .col{ 
	display:table-cell; 
}

.tbl .col:nth-child(odd){
	width:725px;
	padding-right:10px;
}

/*.tbl .col:nth-child(odd) a{
	color:#222222;
	text-decoration:none;
}*/

/*.tbl .col:nth-child(odd) a:hover{
	color:#737373;
	text-decoration:none;
}*/

.tbl .col:nth-child(even){ 
	border-left:1px solid #bebebe;
	padding-left:10px;
	text-align:center;
}


.tbl .col:nth-child(even) a{
	color:#222222;
	text-decoration:none;
}

.tbl .col:nth-child(even) a:hover{
	color:#737373;
	text-decoration:none;
}

.tbl .col:nth-child(even) h1{
	width:200px;
}

.social {
    text-align:right;
	position:absolute;
	bottom:0;
	right:0;
	padding:10px 5px 2px 10px;
}

.social img{
	width:32px;
	height:32px;
	opacity:0.75;
	filter:alpha(opacity=75);
}
.social img:hover{
	opacity:1;
	filter:alpha(opacity=100);
}

li{
	text-align:left;
}

hr{
	color:#bebebe;
	background:#bebebe;
	height:1px;
	border:none;
}

h1{
	color: #222222;
	font-family: Verdana, sans-serif;
	font-size: 22px;
	font-weight: bold;
	letter-spacing: 0px;
	line-height: 1em;
        padding-bottom: 2px;
	text-align: left;
	text-transform: uppercase;
	border-bottom: 1px solid #bebebe;
	padding: 0px;
	margin: 0;
}

h2{
	color: #222222;
	font-family: Verdana, sans-serif;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0px;
	line-height: 1em;
        padding-bottom: 2px;
	text-align: left;
	text-transform: uppercase;
	border-bottom: 1px solid #bebebe;
	padding-left: 0px;
	margin: 0;
}

h3{
	color: #222222;
	font-family: Verdana, sans-serif;
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0px;
	line-height: 1em;
        padding-bottom: 1px;
	text-align: left;
	border-bottom: 1px solid #bebebe;
	padding-left: 0px;
	margin: 0;
}

.footer, .push {
	height: 50px;
}

.footer{
	background:#3d3d3d;
	padding-top: 10px;
        padding-bottom: 20px;
        padding-right: 10px;
        padding-left: 10px;
	width:972px;
	margin:0 auto;
	color:#FFF;
	font-size:12px;
	line-height:18px;
}

.footer .right{
	text-align:right;
}


.footer a:link {
	color:#FFFFFF;
	text-decoration:none;
        font-weight:bold;
}  
    
.footer a:visited {
	color:#FFFFFF;
	text-decoration:none;
}  

.footer a:hover {
	color:#c1c1c1;
	text-decoration:none;
}  

.footer a:active {
	color:#FFFFFF;
	text-decoration:none;
} 

#error_box {
    background: #ffffff;
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 11px;
    padding: 5px;
    width: 98%;
}

#error_box h1 {
       line-height: 1.4em;
       border-bottom: 1px #090909 solid;
       font-size: 16px;
       letter-spacing: 2px;
       width: 100%;
       text-align: left;
}

#top_header {
    background: #F0F0F0;
    font-weight: normal;
    letter-spacing: -1px;
    font-size: 11px;
    color: #292929;
    padding: 3px;
    border: 1px solid #D9D9D9;
}

#table_border {
    padding: 1px;
    width: 99%;
    border: 1px solid #CCC;
    text-transform: none;
    font-family: Verdana, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 1em;
    color: #000000;

}
#table_content {
    padding: 4px;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    text-transform: none;
    letter-spacing: normal;
    line-height: 1.5em;
    color: #1F1F1F;
}

#table_header {
    padding: 4px;
    background-color: #222222;
    font-family: Verdana, sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 1.5em;
    color: #FFFFFF;
}

#fade { /*--Transparent background layer--*/
    display: none; /*--hidden by default--*/
    background: #000;
    position: fixed; left: 0; top: 0;
    width: 100%; height: 100%;
    opacity: .80;
    z-index: 9999;
}
.popup_block{
    display: none; /*--hidden by default--*/
    background: #fff;
    padding: 20px;
    border: 20px solid #ddd;
    float: left;
    font-size: 1.2em;
    position: fixed;
    top: 50%; left: 50%;
    z-index: 99999;
    /*--CSS3 Box Shadows--*/
    -webkit-box-shadow: 0px 0px 20px #000;
    -moz-box-shadow: 0px 0px 20px #000;
    box-shadow: 0px 0px 20px #000;
    /*--CSS3 Rounded Corners--*/
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
img.btn_close {
    float: right;
    margin: -55px -55px 0 0;
}
/*--Making IE6 Understand Fixed Positioning--*/
*html #fade {
    position: absolute;
}
*html .popup_block {
    position: absolute;
}


input,
textarea,
select {
-webkit-appearance:none;

padding: 0.5em;
line-height: 1.2em;
width: 75%;

background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ccc));
-webkit-appearance: none;
-webkit-box-shadow: 1px 1px 1px #fff;
-webkit-border-radius: 0.5em;

border: 1px solid #D0D0D0;
background: #FBFBFB;
-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
-moz-box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.05);
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
font-size: 14px;
color: #444;
}

input[type="text"]:focus,
select:focus,
textarea:focus {
background: #fff;
border: 1px solid #52A8E8;
-webkit-box-shadow: 0 0 1px 1px #eee;
box-shadow: 0 0 1px 1px #eee;
-moz-box-shadow: 0 0 1px 1px #eee;	
}

input[type=checkbox],
input[type=radio] {
border: 1px solid #c0c0c0;
margin: 0 0.1em 0 0;
padding: 0;
font-size: 20px;
line-height: .4em;
width: 1em;
height: 1em;
background: #fff;
background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ededed), to(#fbfbfb));

-webkit-appearance: none;
-webkit-box-shadow: 1px 1px 1px #fff;
-webkit-border-radius: 0.25em;
vertical-align: text-top;
display: inline-block;

}

input[type=radio] {
-webkit-border-radius: 2em; /* Make radios round */
}

input[type=checkbox]:checked::after {
content:"■";
display:block;
text-align: center;
font-size: 17px;
height: 16px;
line-height: 16px;
}

input[type=radio]:checked::after {
content:"?";
display:block;
height: 16px;
line-height: 15px;
font-size: 20px;
text-align: center;
}

select {
border: 1px solid #D0D0D0;
background: url(http://www.quilor.com/i/select.png) no-repeat right center, -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fbfbfb), to(#ededed));
background: -moz-linear-gradient(19% 75% 90deg,#ededed, #fbfbfb);
-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
-moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
color: #444;
}

select:focus {
background: #fff url(http://www.quilor.com/i/select.png) no-repeat right center;
}

textarea {
height: 175px;
}

::-webkit-input-placeholder {
color: #444;
}

:-moz-placeholder {  
color: #444;
}

.bar_background {
    background-color: #FFF;
    border: 1px solid #000;
    width: 150px;
    height: 10px;
}
.bar_over {
    background-color: #000;
    height: 10px;
}

/*--Tooltip Styles--*/
.tip {
	color: #fff;
	background:#1d1d1d;
	display:none; /*--Hides by default--*/
	padding:10px;
	position:absolute;	z-index:1000;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
#other-text
{
display:none;
}