input[type="submit"] + a {
    margin-left: 22px;
}

input[type="submit"],.btn.btn-default {
    background: #222222;
    border: 0;
    border-bottom: 2px solid #999999;
    color: #FFFFFF;
    padding: 9px 21px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: auto;
}

input[type="submit"]:hover, input[type="submit"]:active, .btn.btn-default:hover {
    cursor: pointer;
    background: #999999;
    border: 0;
    border-bottom: 2px solid transparent;
}

input[type="submit"] + a, .btn.btn-default {
    margin-left: 22px;
}