/* onto CSS now */
@import url(http://fonts.googleapis.com/css?family=Open+Sans:600);

body.user-profile {
	padding: 20px;
	background: whiteSmoke;
    font-family: 'Open Sans', sans-serif;

    h1{
        color: #222222;
        font-family: "Trebuchet MS", sans-serif;
        font-size: 25px;
        font-weight: bold;
        letter-spacing: -1px;
        line-height: 1em;
        padding-bottom: 2px;
        text-align: left;
        text-transform: uppercase;
        border-bottom: 1px solid #bebebe;
        padding-left: 0px;
        margin: 0;
    }

    a, a:hover, a:visited {
        color: #222222;
        text-decoration: none;
        font-size: 14px;
    }

    #menu {
        text-align: center;
    }

    .nav {
        list-style: none;
        display: inline-block; /* for centering */
        border: 1px solid #b8b8b8;
        font-size: 14px;
        margin: 0; padding: 0;
    }

    .nav li {
        border-left: 1px solid #b8b8b8;
        float: left;
    }
    .nav li:first-child {
        border-left: 0;
    }

    .nav a {
        color: #2f2f2f;
        padding: 0 20px;
        line-height: 32px;
        display: block;
        text-decoration: none;
        
        background: #fbfbfb;
        background-image: linear-gradient(#fbfbfb, #f5f5f5);
    }

    .nav a:hover {
        background: #fcfcfd;
        background-image: linear-gradient(#fff, #f9f9f9);
    }

    .nav a.active,
    .nav a:active {
        background: #e8e8e8;
        background-image: linear-gradient(#e8e8e8, #f5f5f5);
    }


    /* Tab Panes now */

    #tab_panes {
        max-width: 600px;
        margin: 20px auto;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        font-weight: normal;
    }

    .tab_pane {
        display: none;
    }
    .tab_pane.active {
        display: block;
    }

    #tab_panes div {
        background-color: #FFF;
        padding: 10px;
        width: 600px;
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
    }

    input[type="submit"] {
    background: #222222;
    border: 0;
    border-bottom: 2px solid #999999;
    color: #fff;
    padding: 9px 21px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: auto;
    }

    input[type="submit"]:hover,
    input[type="submit"]:active {
    cursor: pointer;
    background: #999999;
    border: 0;
    border-bottom: 2px solid transparent;
    }

    input[type="submit"] + a {
    margin-left: 22px;
    }
}