.padding(@size, @target) {
    .padding-@{size}-@{target} {
        padding-@{target}: 5px;
    }
};

.padding-sml(@target) {
    .padding(sml, @target)
};

.padding-med(@target) {
    .padding(med, @target)
};

.padding-sml(left);
.padding-sml(right);
.padding-sml(top);
.padding-sml(bottom);