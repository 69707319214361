.icon {
    width: 20px;
    display: inline-block;
}

.icons(@target) {
    .icon.icon-@{target}:before {
        content: url("@{image-assets}layouts/icons/@{target}.png");
    }
};

.icons-mail(@target) {
    .icon.icon-mail-@{target}:before {
        content: url("@{image-assets}layouts/icons/mail/@{target}.png");
    }
};

.icons(camera);
.icons(gameicon);
.icons(gear);
.icons(heart);
.icons(key);
.icons(mail);
.icons(pen);
.icons(user);

.icons-mail(unread);
.icons-mail(read);