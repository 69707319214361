#signup-table {
    width:60%;
    border:0px;

    td {
        text-align: left;
    }

    label {
        font-weight: bold;
    }

    input {
        width: 200px;
        padding-left: 10px;
        padding-right: 10px;
    }

    select {
        width: 220px;
    }

    tfoot {
        td {
            text-align: center;
            padding:10px 0px 4px 0px;
        }
    }
}