#social {
    width: 150px;
    margin-left: -120px;

    .social-media(@target) {
        .@{target} {
            content: url("@{image-assets}social/@{target}.png")
        }
    };

    .social-media(tumblr);
    .social-media(twitter);
    .social-media(facebook);
}